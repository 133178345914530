@font-face {
  src: url('/fonts/Inter-Regular.ttf') format('truetype');
  font-family: Inter;
  font-style: normal;
  font-display: swap;
}
.question-modal {
    font-family: Inter;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.4rem;
}

.custom-style-header{
    border: none;
}
.custom-style-body{
    padding-top:0;
}

.button {
    padding: var(--padding-xs) var(--padding-base);
    border-radius: var(--padding-xs);
    border: none;
}

.body-content{
    display:flex;
    justify-content: flex-end;
    gap:var(--padding-xs);
    margin-top: var(--padding-lg);
}