.global-divider {
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  color: #666666;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.global-divider:before,
.global-divider:after {
  content: '';
  flex: 1 1;
  border-bottom: 1px solid #d9d9d9;
  margin: auto;
}
.global-divider:before {
  margin-right: 0.5rem;
}
.global-divider:after {
  margin-left: 0.5rem;
}
