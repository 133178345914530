.container-add-new {
  display: flex;
  align-items: center;
  background-color: var(--grays-gray-00-f9f9f9);
  justify-content: space-between;
  border-radius: var(--padding-xs1);
  cursor: pointer;
  p {
    padding: var(--padding-base);
  }
  .action-menu {
    button {
      background: none;
      border-color: transparent;
      &:hover {
        background: none;
        border-color: transparent;
      }
    }

    height: max-content;
    padding: var(--padding-xs);
    border-left: 1px solid var(--grays-gray-03-d9d9d9);
  }
}

.container-form {
  width: 100%;
  border: none;
  background: var(--grays-gray-00-f9f9f9);
  padding: var(--padding-base);

  margin-bottom: var(--padding-lg);
  h5 {
    margin: 0;
    font-size: var(--font-size-xs);
    line-height: var(--font-size-xs);
  }
  .formField,
  .container-with-label {
  & > .form-label {
    font-weight: 600;
    font-size: var(--font-size-lg);
    line-height: var(--font-size-lg);
    margin-bottom: var(--padding-lg);
  }
}
}

.container-option {
  display: flex;
  flex-direction: column;
  gap: var(--padding-xs);
  .dropdownToggle::after {
    display: none;
  }
}

.container-with-label {
  width: 100%;
}


