@keyframes skeleton-loading {
    0% { background-color: #e0e0e0; }
    50% { background-color: #f0f0f0; }
    100% { background-color: #e0e0e0; }
  }
  
  .skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    animation: skeleton-loading 1.2s ease-in-out infinite;
  }